import React, { useState, useRef, useContext, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import apiService from 'utils/apiService';
import { useHistory, useParams } from 'react-router-dom';
import Overflow from 'react-overflow-indicator';
import { useCookies } from 'react-cookie';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import Modal from 'components/Modal/Modal';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';

import { ReactComponent as CopyIcon } from 'images/icons/copy.svg';
import AppContext from 'AppContext';

import Log from 'utils/Log';
import Button from 'components/CustomButtons/Button';
import Typing from 'dashboard/Typing';

import NotificationBar from './NotificationBar';
import useSsoConnection from 'hooks/useSsoConnection';

const getLabel = (flag) => {
  const flags = {
    0: 'safe',
    1: 'sensitive',
    2: 'unsafe'
  };
  return flags[flag];
};

const ICEBREAKER_COOKIE_NAME = 'ibtoken';
const FREE_CREDITS = 3;

export default function IceBreakerQuestions() {
  const history = useHistory();
  const { isAuthenticated, profile, org = { teams: [] } } = useContext(AppContext);
  const { teamId: selectedTeamId } = useParams();
  const teamId = selectedTeamId || org.teams[0];
  const inputEl = useRef(null);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState();
  const [reachLimit, setReachLimit] = useState(false);
  const [remainingCount, setRemainingCount] = useState();
  const [allowedMaxQuestions, setAllowedMaxQuestions] = useState();
  const [hasPlan, setHasPlan] = useState();
  const [error, setError] = useState();
  const { loginUrl } = useSsoConnection({ teamId, isDeepLink: true });
  const [cookies, setCookie] = useCookies([ICEBREAKER_COOKIE_NAME]);
  const freeCredits = parseInt(cookies[ICEBREAKER_COOKIE_NAME]) || 0;
  const [noCredits, setNoCredits] = useState(Boolean(freeCredits > FREE_CREDITS));

  const { picture } = profile || {};

  const askQuestion = ({ topic }) => {
    setLoading(true);

    const apiUrl = teamId ? `/api/team/${teamId}/icebreaker/question` : '/api/public/icebreaker-bot';

    apiService
      .get(apiUrl, {
        params: {
          topic
        }
      })
      .then(({ data }) => {
        const { text, flag, updatedUsage } = data || {};
        setResults([{ topic, text, flag }, ...results]);
        setLoading(false);
        setRemainingCount(Math.max(allowedMaxQuestions - updatedUsage, 0));

        if (!isAuthenticated) {
          setCookie(ICEBREAKER_COOKIE_NAME, freeCredits + 1, {
            path: '/',
            sameSite: 'lax',
            maxAge: 60 * 60 * 24 * 365
          });
        }

        const questions = text?.trim().split('\n');
        questions.forEach((question) => {
          Log('Icebreaker Bot', `${topic} - ${question}`);
        });
      })
      .catch((e) => {
        setLoading(false);
        if (e?.response?.status === 429) {
          setReachLimit(true);
        } else if (e?.response?.status === 403) {
          setError(
            'Sorry something went wrong. Please contact your team admin to check if you have the right permissions.'
          );
        } else {
          setError(
            'Uh oh! Our AI bot is taking a break right now. Please try again later when it’s feeling more sociable. 😴🤖'
          );
        }
      });
  };

  const onSearch = () => {
    const value = inputEl.current?.value;

    askQuestion({ topic: value });
  };

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      onSearch();
    }
  };

  const onCopy = (text) => {
    Log('Copy', `icebreaker - ${text}`);
  };

  const renderQuestion = (question, index) => {
    const valideQuestion = question && question.trim();

    if (!valideQuestion) return null;

    return (
      <div
        className="searchGroup-results--icebreaker--wrapper"
        key={`icebreaker-${index}`}
        style={{ display: 'flex', marginBottom: '12px' }}
      >
        <span className="searchGroup-results--icebreaker--result">{valideQuestion}</span>
        <CopyToClipboard text={valideQuestion} onCopy={onCopy}>
          <IconButton>
            <CopyIcon fontSize="small" />
          </IconButton>
        </CopyToClipboard>
      </div>
    );
  };

  const renderSearchResults = () => {
    return (
      <Overflow className="searchGroup-results">
        <Overflow.Content>
          {loading && (
            <div className="icebreaker-list">
              <div>
                {inputEl.current?.value && (
                  <span
                    className="searchGroup-results--icebreaker--result"
                    style={{ color: 'var(--blue)', backgroundColor: '#E9F5FF' }}
                  >
                    {inputEl.current?.value}
                  </span>
                )}
              </div>
              <div>
                <span
                  className="searchGroup-results--icebreaker--result"
                  style={{ padding: '9px 15px', backgroundColor: 'transparent' }}
                >
                  <Typing />
                </span>
              </div>
            </div>
          )}
          {Array.isArray(results) &&
            results.length > 0 &&
            results.map(({ topic, text, flag }, i) => {
              const isUnsafe = Boolean(flag !== '0');
              const questions = text?.trim().split('\n');

              return (
                <div className="icebreaker-list" key={`icebreaker-question-${i}`}>
                  <div>
                    {topic && (
                      <span
                        className="searchGroup-results--icebreaker--result"
                        style={{ color: 'var(--blue)', backgroundColor: '#E9F5FF' }}
                      >
                        {topic}
                      </span>
                    )}
                    {isUnsafe && getLabel(flag) && (
                      <span
                        className="searchGroup-results--icebreaker--result"
                        style={{
                          background: flag === '1' ? 'rgba(255,210,51, 0.15)' : 'rgba(242,108,124, 0.1)',
                          marginLeft: '8px',
                          color: flag === '1' ? '#CFA612' : 'var(--red)'
                        }}
                      >
                        {getLabel(flag)}
                      </span>
                    )}
                  </div>
                  {isUnsafe ? (
                    <div>
                      <span className="searchGroup-results--icebreaker--result  searchGroup-results--icebreaker--result--unsafe">
                        {text}
                      </span>
                    </div>
                  ) : (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                      {questions.map(renderQuestion)}
                    </div>
                  )}
                </div>
              );
            })}
        </Overflow.Content>
        <Overflow.Indicator direction="up">{!loading && <div className="contentScroll-top"></div>}</Overflow.Indicator>
        <Overflow.Indicator direction="down">
          {!loading && <div className="contentScroll-down"></div>}
        </Overflow.Indicator>
      </Overflow>
    );
  };

  const renderUsage = () => {
    if (!allowedMaxQuestions) return null;

    if (hasPlan === false) {
      return (
        <span className="promoLink">
          <div className="retros-count">
            <div className="retros-count-numbers">
              Requests remaining: {remainingCount} of {allowedMaxQuestions}
            </div>
            <div className="retros-count-container" style={{ width: '220px' }}>
              <div
                className="retros-count-bar"
                style={{ width: `${Math.round((remainingCount / allowedMaxQuestions) * 100)}%` }}
              ></div>
            </div>
          </div>

          <span className="text-button " onClick={() => history.push('/plans')}>
            Unlock more questions{' '}
            <span role="img" aria-label="">
              🚀
            </span>
          </span>
        </span>
      );
    }

    if (hasPlan === true) {
      return (
        <span style={{ position: 'absolute', top: '68px', right: '19px' }}>
          Monthly Usage{' '}
          <Tooltip
            title={
              <>
                <p style={{ margin: '8px', fontSize: '16px' }}>
                  Requests remaining: {remainingCount} of {allowedMaxQuestions}
                </p>
              </>
            }
            placement="bottom"
          >
            <InfoOutlinedIcon style={{ fontSize: 16 }} />
          </Tooltip>
        </span>
      );
    }
  };

  useEffect(() => {
    if (!teamId) return;

    apiService
      .get(`/api/team/${teamId}/icebreaker/usage`)
      .then(({ data }) => {
        const { currentUsage, limit, planId } = data;
        setRemainingCount(Math.max(limit - currentUsage, 0));
        setAllowedMaxQuestions(limit);

        if (currentUsage >= limit) {
          setReachLimit(true);
        }

        setHasPlan(Boolean(planId));
      })
      .catch((e) => e);
  }, [teamId]);

  useEffect(() => {
    if (freeCredits > FREE_CREDITS) {
      setNoCredits(true);
    }
  }, [freeCredits]);

  return (
    <div>
      {renderUsage()}

      <div className="team-container-header">
        <h2>Icebreaker Bot</h2>
        <p>Bring a smile to your team with entirely new icebreaker questions</p>
        <hr />
      </div>
      <Paper>
        <div className="searchGroup-results--icebreaker">
          <div className="icebreaker-list" style={{ marginBottom: '-12px' }}>
            <div>
              <span role="img" aria-label="" style={{ fontSize: '30px' }}>
                🤖
              </span>
            </div>
            <div>
              <span className="searchGroup-results--icebreaker--result">Hello!</span>
            </div>
          </div>
          <div className="icebreaker-list">
            <div></div>
            <div>
              <span className="searchGroup-results--icebreaker--result">
                Give me a topic and I’ll return an icebreaker question
              </span>
            </div>
          </div>

          <div
            className="icebreaker-list"
            style={{
              margin: '36px 0 -28px'
            }}
          >
            <div></div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end'
              }}
            >
              <TextField
                inputRef={inputEl}
                variant="outlined"
                placeholder="e.g. movies"
                onKeyUp={keyPress}
                inputProps={{
                  maxLength: 28 // this apply on input
                }}
                InputProps={{
                  className: 'iceBreaker-field' // this apply on input parent
                }}
                disabled={!isAuthenticated && noCredits}
              />
              <Avatar className="profileImg" alt="" src={picture} />
            </div>
          </div>
          {results && renderSearchResults()}
        </div>
      </Paper>
      <Modal
        status={reachLimit}
        maxWidth="md"
        onClose={() => {
          setReachLimit(false);
        }}
        nonResponsive
        PaperProps={{
          style: {
            maxWidth: '700px'
          }
        }}
      >
        <div className="welcomeModal">
          <h2>
            Unlock more questions{' '}
            <span role="img" aria-label="" style={{ fontSize: '30px' }}>
              🚀
            </span>
          </h2>
          <div>
            {hasPlan === false ? (
              <div>
                <p>You are currently on the Free plan which allows 10 icebreaker questions.</p>
                <p>Please upgrade your plan to unlock more AI generated icebreaker questions.</p>
              </div>
            ) : (
              <div>
                <p>{`You have reached your limit of ${allowedMaxQuestions} icebreaker questions this month.`}</p>
                <p>Please upgrade your plan to unlock more AI generated icebreaker questions.</p>
              </div>
            )}

            <Button onClick={() => history.push('/plans')}>{hasPlan ? 'Update Plan' : 'Compare Plans'}</Button>
          </div>
        </div>
      </Modal>
      <Modal
        status={!isAuthenticated && noCredits}
        maxWidth="md"
        nonResponsive
        PaperProps={{
          style: {
            maxWidth: '700px'
          }
        }}
      >
        <div className="welcomeModal">
          <h2 style={{ marginTop: '24px' }}>
            <span role="img" aria-label="" style={{ fontSize: '30px' }}>
              🚀
            </span>{' '}
            Ready for more icebreakers?
          </h2>
          <div>
            <div>
              <p>Please Sign in to unlock more AI generated icebreaker questions!</p>
            </div>

            <Button onClick={() => window.location.replace(loginUrl)}>Sign In</Button>
          </div>
        </div>
      </Modal>
      {error && (
        <NotificationBar message={error} type="error" autoHideDuration={5000} closeCallback={() => setError()} />
      )}
    </div>
  );
}
