import React, { useState, useEffect, useContext } from 'react';
import Log from 'utils/Log';

import { useHistory, useParams } from 'react-router-dom';
import { format as dateFormat } from 'date-fns';

import Button from 'components/CustomButtons/Button';
import Modal from 'components/Modal/Modal';
import TextField from '@material-ui/core/TextField';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { ReactComponent as HelpIcon } from 'images/icons/question-mark.svg';

import { makeStyles } from '@material-ui/core/styles';

import { APP_ENDPOINT } from 'config';
import AppContext from 'AppContext';
import apiService from 'utils/apiService';
import { getRole, checkPermission } from 'roles/checkPermission';

import { ReactComponent as EditIcon } from 'images/icons/edit.svg';

import HomeMenu from 'modules/HomeMenu';
import ProgressLoader from 'modules/ProgressLoader';
import NotificationBar from 'modules/NotificationBar';

import { images } from '../images/background';
import './NewBoard.css';

import { getBackgroundImage, getImageUrl } from '../images/background';
import ColumnHeading from './ColumnHeading';

import BackgroundPicker from './BackgroundPicker';

import { trimRight, getFormatUrl } from 'utils/misc';
import useQuery from 'hooks/useQuery';
import { Tooltip } from '@material-ui/core';
import RetroFormatBot from './RetroFormatBot';
import useFormats from 'hooks/useFormats';

const useStyles = makeStyles((theme) => ({
  titleTextField: {
    width: '100%'
  },
  tooltip: {
    width: '210px'
  }
}));

const MAX_COLUMN = 5;

const DEFAULT_FORMAT = 0; //Agile //Math.floor(Math.random() * (formats.length - 1)) + 1;
const DEFAULT_IMAGE_ID = Math.floor(Math.random() * images.length);

const NewBoard = () => {
  const history = useHistory();
  const query = useQuery();
  const { teamId: selectedTeamId } = useParams();
  const fromBoard = query.get('fromBoard');
  const fromFormat = query.get('format');
  const { profile, isAuthenticated, switchOrg, org = { teams: [] }, userMeta } = useContext(AppContext);
  const teamId = selectedTeamId || org.teams[0];
  const { formats } = useFormats();
  const [useDefault, setUseDefault] = useState(Boolean(!fromBoard && !fromFormat));
  const [name, setName] = useState('');
  const [format, setFormat] = useState();
  const [cols, setCols] = useState();
  const [startMoodPoll, setStartMoodPoll] = useState(true);
  const [background, setBackground] = useState();
  const [titleEditor, setTitleEditor] = useState(false);
  const [columnEditor, setColumnEditor] = useState(false);
  const [backgroundSelector, setBackgroundSelector] = useState(false);
  const [maxBoards, setMaxBoards] = useState(false);
  const [backgroundUrl, setBackgroundUrl] = useState();
  const [showRetroBot, setShowRetroBot] = useState(true);
  const [error, setError] = useState(false);
  const [serviceError, setServiceError] = useState(false);
  const { id: orgId } = org || {};
  const [teamData, setTeamData] = useState();
  const { role } = getRole({ orgId, teamId, userMeta });
  const canEditBilling = checkPermission(role, 'billing:edit');
  const classes = useStyles();

  const createBoard = async () => {
    let columns = cols;

    if (columns.length === 0) {
      columns = [''];
    }

    const boardData = {
      name,
      columns,
      theme: background,
      backgroundUrl,
      compact: true,
      timestamp: Date.now(),
      createdBy: profile && profile.sub,
      teamId,
      startMoodPoll
    };

    try {
      const newBoardId = await apiService.post(`/api/team/${teamId}/boards`, boardData).then(({ data }) => {
        return data;
      });

      serviceError && setServiceError(false);

      const usedFormat = `${formats[format] ? formats[format].name : format ? 'Retro Bot' : fromFormat}`;

      Log('Format', `mood poll ${startMoodPoll ? 'on' : 'off'}`);
      Log('Format', `${formats[format] ? formats[format].name : format ? 'Retro Bot' : fromFormat}`);

      if (usedFormat === 'Retro Bot') {
        Log('Format', `${format} - ${columns.toString()}`);
      }

      history.replace(`/board/${newBoardId}`);
    } catch (e) {
      //Todo: handle 403 error
      setServiceError(true);
    }
  };

  const onChange = (e) => {
    const value = e.target.value;
    setName(value);
  };

  const onColumnChange = (e, i) => {
    let newCols = [...cols];
    const value = e.target.value;

    newCols[i] = value;
    setCols(trimRight(newCols));
  };

  const onSubmit = () => {
    createBoard();
  };

  const onSelect = (e, formatId) => {
    if (format === 'ai-format' || formatId === 'ai-format') {
      setShowRetroBot(!showRetroBot);
      return;
    }

    if (formatId === undefined || (formatId === null && format === undefined)) return;

    const { content, theme } = formats[formatId === null ? format : formatId];
    setFormat(formatId);
    setCols(trimRight(content));
    if (theme) {
      setBackground(theme);
    } else {
      setBackground(images[DEFAULT_IMAGE_ID]);
    }

    setBackgroundUrl();
  };

  const updateBackground = (value) => {
    setBackground(value);
    setBackgroundUrl();
  };

  const onSelectCheckIn = (e) => {
    const value = e.target.value === 'true' ? true : false;

    setStartMoodPoll(value);
  };

  const renderColumns = (text, i) => {
    return (
      <div key={`create-board-column-${i}`} className="board-column" id={`retro-col-${i}`}>
        <h3>
          <ColumnHeading text={text} />
        </h3>
      </div>
    );
  };

  const renderColumnsEditor = (i) => {
    return (
      <div key={`create-board-column-${i}`} className="newBoardCols">
        <TextField value={(cols && cols[i]) || ''} id={`retro-col-${i}`} onChange={(e) => onColumnChange(e, i)} />
      </div>
    );
  };

  const renderFormatCards = () => {
    return formats.map(renderFormatCard);
  };

  const renderFormatCard = (format, i) => {
    const { name, theme } = format;

    return (
      <ToggleButton disableRipple value={i} key={`format-${i}-button`}>
        <div className="retro-format">
          <div className="retro-format-card">
            <a
              href={`https://www.teleretro.com/retro-formats/${getFormatUrl(name)}`}
              target="_blank"
              className="retro-format-info"
              rel="noopener noreferrer"
              title="Learn more"
            >
              <HelpIcon />
            </a>
            <h3>{name}</h3>
            <img src={getImageUrl(theme || images[DEFAULT_IMAGE_ID], 's')} alt="" width="160" />
          </div>
        </div>
      </ToggleButton>
    );
  };

  const onClose = (e) => {
    history.replace(`/dashboard/team/${teamId}`);
  };

  useEffect(() => {
    if (teamId) {
      apiService
        .get(`/api/team/${teamId}`)
        .then(({ data }) => {
          const { name: teamName, credits, active } = data;
          if (teamName) {
            setName(`${teamName} ${dateFormat(new Date(), 'PP')}`);
          }

          if (!active && credits <= 0) {
            setMaxBoards(true);
          }
          setTeamData(data);
        })
        .catch((e) => {
          setServiceError(e);
        });
    }
  }, [teamId]);

  useEffect(() => {
    if (teamData && teamData.orgId) {
      const { orgId } = teamData;
      switchOrg({ orgId });
    }
  }, [teamData, switchOrg]);

  useEffect(() => {
    if (fromBoard) {
      apiService
        .get(`/api/board/${fromBoard}`)
        .then(({ data }) => {
          const { columns, theme, backgroundUrl } = data;
          setCols(columns);

          if (backgroundUrl) {
            setBackgroundUrl(backgroundUrl);
          } else {
            setBackground(theme);
          }
        })
        .catch((e) => {
          setUseDefault(true);
        });

      Log('Board', 'create from board');
    }
  }, [fromBoard]);

  useEffect(() => {
    if (fromBoard || !fromFormat) return;

    const selectedFormat = formats.filter(({ name }) => {
      return getFormatUrl(name) === fromFormat?.toLowerCase();
    })[0];

    if (selectedFormat) {
      const { content, theme } = selectedFormat;

      setCols(trimRight(content));

      if (theme) {
        setBackground(theme);
      } else {
        setBackground(images[DEFAULT_IMAGE_ID]);
      }
    } else {
      setUseDefault(true);
    }
  }, [fromBoard, fromFormat, formats]);

  useEffect(() => {
    if (useDefault && !cols) {
      setFormat(useDefault ? DEFAULT_FORMAT : '');
      setCols(useDefault ? formats[DEFAULT_FORMAT].content : []);
      setBackground(useDefault ? images[DEFAULT_IMAGE_ID] : null);
    }
  }, [useDefault, formats, cols]);

  return (
    <div className="newBoard">
      <div
        className="boardMain"
        style={{ backgroundImage: getBackgroundImage({ background, backgroundUrl, size: 'l' }) }}
      >
        {!(background || backgroundUrl) && <ProgressLoader />}

        <div className="boardMenu">
          <HomeMenu teamId={teamId} />
          <h1 className="boardTitle boardTitle-facilitator" id="boardName" onClick={() => setTitleEditor(true)}>
            {name}
          </h1>
        </div>

        <div className="main mainNewBoard">
          <div className="panel">
            <div className="board-columns-wrapper">
              <div onClick={() => setColumnEditor(true)} className="board-columns board-columns-facilitator">
                {cols && cols.map(renderColumns)}
              </div>
            </div>
          </div>
        </div>

        <div className="retroSettings-wrapper">
          <div className="retroSettings">
            <h2>Start a retrospective</h2>

            <div className="retroSettings-field">
              <label className="label">Title</label>
              <TextField id="retroName-name" value={name} inputProps={{ maxLength: 92 }} onChange={onChange} />
            </div>

            <div className="retroSettings-field">
              <label className="label" onClick={() => setColumnEditor(true)}>
                Columns
                <EditIcon />
              </label>
              <p onClick={() => setColumnEditor(true)}>Select or edit a format</p>
            </div>

            <div className="retroSettings-field">
              <label className="label" onClick={() => setBackgroundSelector(true)}>
                Background
                <EditIcon />
              </label>
              <div
                onClick={() => setBackgroundSelector(true)}
                className="backgroundThumbnail"
                style={{
                  backgroundImage: getBackgroundImage({ background, backgroundUrl, size: 's' }),
                  cursor: 'pointer'
                }}
              ></div>
            </div>

            <div className="retroSettings-field">
              <label className="label" style={{ marginBottom: '-4px' }}>
                Mood Poll
              </label>

              <RadioGroup row aria-label="Check In" name="Check In" value={startMoodPoll} onChange={onSelectCheckIn}>
                <Tooltip
                  placement="top"
                  title="The Mood Poll will be shown to users when they join the retro"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <FormControlLabel value={true} control={<Radio color="primary" disableRipple />} label="On" />
                </Tooltip>
                <Tooltip
                  placement="top"
                  title="The Mood Poll can be launched at any time during the retro"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <FormControlLabel value={false} control={<Radio color="primary" disableRipple />} label="Off" />
                </Tooltip>
              </RadioGroup>
            </div>

            <div className="retroSettings-field">
              {isAuthenticated ? (
                <Button disabled={Boolean(maxBoards)} onClick={onSubmit}>
                  Get started
                </Button>
              ) : (
                <a className="link-button" href={`${APP_ENDPOINT}/login?returnTo=${window.location.href}`}>
                  Sign In to Create a Retro
                </a>
              )}
            </div>
          </div>
        </div>

        <RetroFormatBot
          showRetroBot={showRetroBot}
          teamId={teamId}
          setFormat={setFormat}
          setCols={setCols}
          setBackground={setBackground}
          setBackgroundUrl={setBackgroundUrl}
          isActive={teamData?.active}
        />

        <div className="retroFormats">
          <ToggleButtonGroup value={format} exclusive onChange={onSelect} aria-label="Retro format">
            <ToggleButton disableRipple value={'ai-format'} key={`format-ai-button`}>
              <div className="retro-format">
                <div className="retro-format-card">
                  <a
                    href={'https://www.teleretro.com/retro-bot'}
                    target="_blank"
                    className="retro-format-info"
                    rel="noopener noreferrer"
                    title="Learn more"
                  >
                    <HelpIcon />
                  </a>
                  <h3 style={{ textIndent: '0' }}>Retro Bot✨</h3>
                  <img src="https://www.teleretro.com/img/backgrounds/retro-bot:400x300.jpg" alt="" width="160" />
                  <span
                    style={{
                      position: 'absolute',
                      top: 'calc(50% - 40px)',
                      left: 'calc(50% - 29px)',
                      fontSize: '58px',
                      lineHeight: '58px'
                    }}
                  >
                    🤖
                  </span>
                </div>
              </div>
            </ToggleButton>
            {renderFormatCards()}
          </ToggleButtonGroup>
        </div>
      </div>

      {serviceError && (
        <NotificationBar
          message="Sorry something went wrong"
          type="error"
          closeCallback={() => setServiceError(false)}
        />
      )}

      <Modal status={titleEditor} maxWidth="sm" onClose={() => setTitleEditor(false)}>
        <div className="retroSettings-columns">
          <div className="field">
            <h3>
              <span role="img" aria-label="">
                🗞
              </span>{' '}
              Title
            </h3>
            <TextField
              id="retroName-name"
              className={classes.titleTextField}
              value={name}
              inputProps={{ maxLength: 92 }}
              onChange={onChange}
            />
          </div>
        </div>
      </Modal>

      <Modal status={columnEditor} maxWidth="sm" onClose={() => setColumnEditor(false)} nonResponsive>
        <div className="retroSettings-columns ">
          <div className="field">
            <h3>
              <span role="img" aria-label="">
                🖌
              </span>{' '}
              Columns
            </h3>

            <div className="retroCols field">
              {[...Array(MAX_COLUMN).keys()].map((i) => renderColumnsEditor(i))}
              <p style={{ textAlign: 'left', fontSize: '15px', alignSelf: 'flex-start' }}>
                <span role="img" aria-label="">
                  💡
                </span>
                Use brackets for (descriptions)
              </p>
            </div>
          </div>
        </div>
      </Modal>

      <BackgroundPicker
        backgroundSelector={backgroundSelector}
        setBackgroundSelector={setBackgroundSelector}
        updateBackground={updateBackground}
        setBackgroundUrl={setBackgroundUrl}
        error={error}
        setError={setError}
      />

      <Modal status={maxBoards} maxWidth="sm" onClose={onClose} nonResponsive>
        <div className="welcomeModal">
          <h2>
            Get unlimited retros{' '}
            <span role="img" aria-label="" style={{ fontSize: '30px' }}>
              🔐
            </span>
          </h2>
          <p>You can create up to 3 retros on your Free plan.</p>
          {canEditBilling ? (
            <div>
              <p>To get unlimited retros please select a plan.</p>

              <Button onClick={() => history.push('/plans')}>Compare Plans</Button>
            </div>
          ) : (
            <p>Please contact your team admin to upgrade for unlimited retros.</p>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default NewBoard;
